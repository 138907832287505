import { Link } from 'gatsby';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { itemWithNumber } from '../../constants/css/itemWithNumber';
import theme from '../../constants/theme';
import { SiteContext } from '../../contexts/SiteContext';
import Title from '../atoms/Title';

interface URLData {
    title: string;
    uri: string;
}

interface Props {
    data: URLData[];
}

const UrlList: FC<Props> = ({ data }) => {
    const site = useContext(SiteContext);
    return (
        <List>
            {data.map(({ title, uri }, i) => {
                return (
                    <Item key={i}>
                        <StyledLink key={i} to={site ? `/${site}/${uri}` : `/${uri}`}>
                            <Title>{title}</Title>
                        </StyledLink>
                    </Item>
                );
            })}
        </List>
    );
};

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const Item = styled.li`
    margin: 2.4rem;
    opacity: 1;
    counter-increment: urlList;
    ${itemWithNumber('urlList')};
`;

const StyledLink = styled(Link)`
    position: relative;
    text-decoration: none;
    color: currentColor;
    display: inline-block;

    &:before {
        position: absolute;
        content: '';
        bottom: -2px;
        left: 0;
        right: 0;
        height: 0.3rem;
        background: currentColor;
        opacity: 1;

        @media screen and (max-width: ${theme.mediaQueries.width.m}) {
            height: 0.2rem;
            bottom: -1px;
        }
    }
`;

export default UrlList;
