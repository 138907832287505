import React, { FC, Fragment } from 'react';
import { graphql } from 'gatsby';
import withDefaultTransition from '../hocs/withDefaultTransition';
import styled from 'styled-components';
import UrlList from './molecules/UrlList';
import { NotFoundFragment, NotFoundQuery } from '../entities/operationResults';
import theme from '../constants/theme';
import ErrorPage from './organisms/ErrorPage';
import Meta from './atoms/Meta';

export const query = graphql`
    query NotFoundQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...NotFoundFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment NotFoundFragment on Craft_Error404 {
        title
        illustration {
            url
        }
        color {
            ...ColorFragment
        }
        contentWithTitle {
            ...ContentWithTitle
        }
        linkList {
            redirectLink {
                customText
                entry {
                    uri
                }
            }
        }
        seo {
            ...MetaFragment
        }
    }

    fragment ContentWithTitle on Craft_ContentWithTitleContenttext {
        bodyTitle
        body
    }

    fragment ColorFragment on Craft_ColorColorCombo {
        color {
            rgb
        }
        accent {
            rgb
        }
    }
`;

interface Props {
    data: NotFoundQuery;
}

const NotFoundPage: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as NotFoundFragment;
    const MetaTitle = entry.title && entry.title;
    const color = entry.color && entry.color[0];
    const mainColor = color && color.color && color.color.rgb;
    const accent = color && color.accent && color.accent.rgb;
    const meta = entry.seo && entry.seo[0];

    const contentWithTitle = entry.contentWithTitle && entry.contentWithTitle[0];
    const header = contentWithTitle && contentWithTitle.bodyTitle;
    const body = contentWithTitle && contentWithTitle.body;
    const illustration = entry.illustration && entry.illustration;

    const links = [];
    if (entry.linkList) {
        for (const index of entry.linkList) {
            const listItem = index && index;
            if (listItem && listItem.redirectLink) {
                const { redirectLink } = listItem;
                const title = redirectLink.customText;

                const uriContainer = redirectLink && redirectLink.entry;
                const uri = uriContainer && uriContainer.uri;

                if (uri && title) {
                    const item = { title, uri };
                    if (item.uri === '__home__') {
                        item.uri = '';
                    }
                    links.push(item);
                }
            }
        }
    }
    return MetaTitle && mainColor && accent && header && body && illustration && links ? (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : MetaTitle}
                description={meta && meta.seoDescription ? meta.seoDescription : body}
                image={meta && meta.seoImage ? meta.seoImage : [{ url2000: illustration?.[0]?.url ?? null }]}
            />
            <ErrorPage mainColor={mainColor} accent={accent} header={header} body={body} illustration={illustration?.[0]?.url ?? ''}>
                <LinkList>
                    <UrlList data={links} />
                </LinkList>
            </ErrorPage>
        </Fragment>
    ) : null;
};

const LinkList = styled.div`
    padding-bottom: 10vh;

    @media (${theme.mediaQueries.vertical}) {
        padding-bottom: 0;
        margin-top: 20px;
    }
`;

export default withDefaultTransition(NotFoundPage);
